import React from "react";
import styled from "styled-components";
import {
  Container,
  Section,
  SectionTitle,
  SectionDescription,
} from "../../components/Section";
import {
  ProductCard,
  ProductBody,
  ProductFooter,
  ProductFigure,
  ProductName,
} from "../../components/ProductStyle";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px;
  @media (min-width: 1200px) {
    margin: 0 -15px;
  }
`;
const ProductGridItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
  }
  @media (min-width: 1200px) {
    padding: 0 15px;
  }
  .card {
    .card-figure {
      border-radius: 10px 10px 0 0;
    }
    .card-body {
      padding: 0 0 20px;
    }
  }
`;

const ProductCategoryListSection = ({ data }) => {
  return (
    <Section
      ept="60px"
      epb="60px"
      xpt="40px"
      xpb="40px"
      pt="30px"
      pb="30px"
      bgColor="#fff"
      bgBefore="#cdd0d6"
      bgBeforeWidth="100%"
      bgBeforeHeight="1px"
      top="0"
    >
      <Container>
        <SectionTitle textAlign="center">{data?.heading}</SectionTitle>
        <SectionDescription textAlign="center">
          {data?.subheading}
        </SectionDescription>
        <ProductGrid>
          {!!data.categories &&
            data.categories.length > 0 &&
            data.categories.map((category, i) => {
              const url = category.title.split(" ").join("-").toLowerCase();

              return (
                <ProductGridItem>
                  <ProductCard className="card">
                    <ProductBody className="card-body">
                      <ProductFigure className="card-figure">
                        <Link key={i} to={`/${url}`}>
                          <GatsbyImage
                            image={category.thumbnail ? category.thumbnail.gatsbyImageData : category.heroImage.gatsbyImageData}
                            alt={category.title}
                          />
                        </Link>
                      </ProductFigure>
                    </ProductBody>
                    <ProductFooter className="card-footer">
                      <ProductName className="product-name">
                        <Link to={`/${url}`}>{category.title}</Link>
                      </ProductName>
                    </ProductFooter>
                  </ProductCard>
                </ProductGridItem>
              );
            })}
        </ProductGrid>
      </Container>
    </Section>
  );
};

export default ProductCategoryListSection;
